@import '@jsluna/foundation/scss/main';

.cls1 {
    fill: none;
    stroke: $ln-color-orange;
    stroke-miterlimit: 10;
    stroke-width: 6px;
}

.cls2 {
    fill: $ln-color-orange;
}

.ulc1,
.ulc2,
.ulc3 {
    animation-duration: 7s;
    animation-fill-mode: forwards;
    transform: translate(-100%, 0);
}

.ulc1 {
    animation-delay: 3s;
    animation-name: ulcMove1;
}

.ulc2 {
    animation-delay: 3.5s;
    animation-name: ulcMove2;
}

.ulc3 {
    animation-delay: 4s;
    animation-name: ulcMove3;
}

@keyframes ulcMove1 {
    30% {
        transform: translate(50%, 0);
    }

    37% {
        opacity: 0;
    }

    to {
        opacity: 0;
        transform: translate(100%, 0);
    }
}

@keyframes ulcMove2 {
    30% {
        transform: translate(42%, 0);
    }

    37% {
        opacity: 0;
    }

    to {
        opacity: 0;
        transform: translate(100%, 0);
    }
}

@keyframes ulcMove3 {
    30% {
        transform: translate(32%, 0);
    }

    37% {
        opacity: 0;
    }

    to {
        opacity: 0;
        transform: translate(100%, 0);
    }
}

.trailer {
    animation-name: trailerMove;
    animation-duration: 10s;
    animation-fill-mode: forwards;
}

@keyframes trailerMove {
    0% {
        transform: translate(-100%, 0);
    }

    50%,
    60% {
        transform: translate(30%, 0);
    }

    to {
        transform: translate(100%, 0);
    }
}
