.logoWrapper {
    position: relative;
    display: block;
    height: 40vh;
}

#logo {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: fit-content;
    animation: fill-logo 2s ease forwards 1.2s;
}

#logo-full {
    position: absolute;
    width: 352px;
    top: calc(40% + 80px);
    left: 50%;
    transform: translateX(-49%);
    min-width: fit-content;
    animation: move-logo-full 2s ease forwards, fill-logo-full 2s ease forwards 0.6s;
}

#logo path:nth-child(1) {
    stroke-dasharray: 426.1333923339844px;
    stroke-dashoffset: 426.1333923339844px;
    animation: line-animation 2s ease forwards;
}
#logo path:nth-child(2) {
    stroke-dasharray: 299.5664367675781px;
    stroke-dashoffset: 299.5664367675781px;
    animation: line-animation 2s ease forwards 0.3s;
}
#logo path:nth-child(3) {
    stroke-dasharray: 454.3787841796875px;
    stroke-dashoffset: 454.3787841796875px;
    animation: line-animation 2s ease forwards 0.6s;
}
#logo path:nth-child(4) {
    stroke-dasharray: 448.5211181640625px;
    stroke-dashoffset: 448.5211181640625px;
    animation: line-animation 2s ease forwards 0.9s;
}

#logo-full path:nth-child(1) {
    stroke-dasharray: 426.1333923339844px;
    stroke-dashoffset: 426.1333923339844px;
    animation: line-animation 2s ease forwards;
}
#logo-full path:nth-child(2) {
    stroke-dasharray: 299.5664367675781px;
    stroke-dashoffset: 299.5664367675781px;
    animation: line-animation 2s ease forwards 0.3s;
}
#logo-full path:nth-child(3) {
    stroke-dasharray: 454.3787841796875px;
    stroke-dashoffset: 454.3787841796875px;
    animation: line-animation 2s ease forwards 0.6s;
}
#logo-full path:nth-child(4) {
    stroke-dasharray: 448.5211181640625px;
    stroke-dashoffset: 448.5211181640625px;
    animation: line-animation 2s ease forwards 0.9s;
}
#logo-full path:nth-child(5) {
    stroke-dasharray: 426.1333923339844px;
    stroke-dashoffset: 426.1333923339844px;
    animation: line-animation 2s ease forwards;
}
#logo-full path:nth-child(6) {
    stroke-dasharray: 299.5664367675781px;
    stroke-dashoffset: 299.5664367675781px;
    animation: line-animation 2s ease forwards 0.3s;
}
#logo-full path:nth-child(7) {
    stroke-dasharray: 454.3787841796875px;
    stroke-dashoffset: 454.3787841796875px;
    animation: line-animation 2s ease forwards 0.6s;
}
#logo-full path:nth-child(8) {
    stroke-dasharray: 448.5211181640625px;
    stroke-dashoffset: 448.5211181640625px;
    animation: line-animation 2s ease forwards 0.9s;
}
#logo-full path:nth-child(9) {
    stroke-dasharray: 426.1333923339844px;
    stroke-dashoffset: 426.1333923339844px;
    animation: line-animation 2s ease forwards;
}
#logo-full path:nth-child(10) {
    stroke-dasharray: 299.5664367675781px;
    stroke-dashoffset: 299.5664367675781px;
    animation: line-animation 2s ease forwards 0.3s;
}
#logo-full path:nth-child(11) {
    stroke-dasharray: 454.3787841796875px;
    stroke-dashoffset: 454.3787841796875px;
    animation: line-animation 2s ease forwards 0.6s;
}
#logo-full path:nth-child(12) {
    stroke-dasharray: 448.5211181640625px;
    stroke-dashoffset: 448.5211181640625px;
    animation: line-animation 2s ease forwards 0.9s;
}
#logo-full path:nth-child(13) {
    stroke-dasharray: 426.1333923339844px;
    stroke-dashoffset: 426.1333923339844px;
    animation: line-animation 2s ease forwards;
}
#logo-full path:nth-child(14) {
    stroke-dasharray: 299.5664367675781px;
    stroke-dashoffset: 299.5664367675781px;
    animation: line-animation 2s ease forwards 0.3s;
}
#logo-full path:nth-child(15) {
    stroke-dasharray: 454.3787841796875px;
    stroke-dashoffset: 454.3787841796875px;
    animation: line-animation 2s ease forwards 0.6s;
}
#logo-full path:nth-child(16) {
    stroke-dasharray: 448.5211181640625px;
    stroke-dashoffset: 448.5211181640625px;
    animation: line-animation 2s ease forwards 0.9s;
}
#logo-full path:nth-child(17) {
    stroke-dasharray: 448.5211181640625px;
    stroke-dashoffset: 448.5211181640625px;
    animation: line-animation 2s ease forwards 0.9s;
}
#logo-full path:nth-child(18) {
    stroke-dasharray: 448.5211181640625px;
    stroke-dashoffset: 448.5211181640625px;
    animation: line-animation 2s ease forwards 0.9s;
}
@keyframes line-animation {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes fill-logo {
    from {
        fill: transparent;
    }
    to {
        fill: #f06c00;
    }
}

@keyframes fill-logo-full {
    from {
        fill: transparent;
    }
    to {
        fill: #4c4c4c;
    }
}

@keyframes move-logo-full {
    to {
        top: calc(40% + 50px);
    }
}
