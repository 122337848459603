@import '@jsluna/foundation/scss/main';

.dichart {
    min-height: 650px;
}

.unplannedHeader {
    text-align: center;
    font-weight: bold;
    color: $ln-color-orange;
}

.statusBar {
    margin-top: -20px;
    margin-bottom: 10px;
}
