@import '@jsluna/foundation/scss/main';

.dateList :global .ln-c-list-group__item {
    padding: 0;
}

.weekNumber {
    height: 48px;
    border: 1px solid $ln-color-orange-dark;
    background-color: $ln-color-orange;
    color: $ln-color-white;
    border-radius: 2px;
    padding: 16px 11px 8px;
    font-weight: bold;
    top: 1px;
    min-width: 48px;
    text-align: center;
    font-size: 20px;
    pointer-events: none;
}

.weekNumber:before {
    content: 'Week';
    font-size: 12px;
    position: absolute;
    display: table;
    float: left;
    top: 2px;
    left: 0;
    text-align: center;
    white-space: nowrap;
    width: 100%;
}

.weekNumberLi {
    cursor: default;
    padding: 0;
    margin-left: -1px;
    margin-top: 1px;
}
