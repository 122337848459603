.fileIcon {
    text-align: center;
    perspective: 1000px;
    width: 85px;
    height: 85px;
    float: left;
    padding-right: 8px;
}

.fileIcon svg {
    height: 100%;
}

.fileIcon.csvIcon {
    position: absolute;
    left: 0;
}
.fileIcon.azureblob,
.fileIcon.jsonIcon {
    position: absolute;
    right: 0;
}

.arrowAnimation {
    width: 100%;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.arrowAnimation svg {
    position: absolute;
    opacity: 1;
}

.arrowAnimation.move svg {
    -webkit-animation: moveArrow 2.4s linear infinite;
    animation: moveArrow 2.4s linear infinite;
}

.arrowAnimation svg:nth-child(1) {
    transform: translateX(-80%) scale(0.5);
}

.arrowAnimation svg:nth-child(2) {
    transform: translateX(0%) scale(1);
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.arrowAnimation svg:nth-child(3) {
    transform: translateX(70%) scale(0.5);
    -webkit-animation-delay: -1.6s;
    animation-delay: -1.6s;
}

@keyframes moveArrow {
    0% {
        opacity: 0;
        transform: translateX(-80%) scale(0.5);
    }
    20% {
        opacity: 1;
        transform: translateX(-40%);
    }
    80% {
        opacity: 0.8;
        transform: translateX(60%) scale(0.8);
    }
    100% {
        opacity: 0;
        transform: translateX(70%) scale(0.5);
    }
}
