.plainLink {
	text-decoration: none;
	font-size: 2rem;
	padding-right: 1rem;
}

// .Content {
// 	margin-top: 72px;
// }

.modal_bg > div {
	background-color: rgb(246, 246, 246) !important;
}

// .cardLink h5 {
// 	color: #f06c00 !important;
// }

.cardLink {
	min-height: 12em;
}

.ignoreMe {
	padding: 0px;
	background: none !important;
	display: -webkit-box;
}
.ignoreMe:before {
	display: none;
}
