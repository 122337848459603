.ln-c-button--text {
	color: #4c4c4c;
	font-weight: 500;
}

.ln-c-header__item ul li button {
	height: calc(100% - 2px);
	display: block;
	min-width: 78px;
	transition: min-width 0.5s;
}

.ln-o-page--is-sticky .ln-c-header__item ul li button {
	min-width: 64px !important;
	transition: min-width 0.5s;
}

.ln-c-header a {
	text-decoration: none;
	padding-left: 10px;
	padding-right: 10px;
}

.ln-c-header ul li:last-child {
	position: absolute;
	right: 0;
	height: inherit;
}

@media (max-width: 720px) {
	.ln-c-header ul li:last-child {
		position: inherit;
		right: inherit;
	}
	.is-open .ln-c-header__main-bar .ln-c-header__item li div {
		display: list-item;
	}
	.is-open .ln-c-header__main-bar .ln-c-header__item li div button {
		width: 100%;
		text-align: left;
	}
	.ln-c-header__divider {
		display: none;
	}
}
