/* Argos PrimeReact theme based on the Omega theme */

/* Modified by A Oakley - 2019-07-08 */
/* Converted to SCSS - 2019-11-28 AO */

@charset "UTF-8";
@import '@jsluna/foundation/scss/main';

.pi {
    font-family: 'primeicons' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* DATA TABLE */

body a {
    color: $ln-color-orange;
}

body a:hover {
    color: $ln-color-orange-dark;
}

body a:focus {
    box-shadow: none;
}

body .p-datatable-row {
    font-family: Arial, sans-serif;
}

body .p-datatable .p-datatable-tbody > tr {
    height: 48px !important;
}

body .p-datatable .p-datatable-tbody > tr > td {
    border: none;
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: $ln-color-grey-v-light;
}

body .p-datatable .p-datatable-thead > tr > th {
    border: none;
    background-color: $ln-color-white;
}

body .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background-color: $ln-color-white;
    color: $ln-color-black;
}

body .p-datatable .p-sortable-column.p-highlight {
    background-color: $ln-color-white;
    color: $ln-color-black;
}

body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: $ln-color-grey;
}

body .ui-widget,
body .p-component {
    font-family: MaryAnn, 'Trebuchet MS', sans-serif;
    font-size: 1em;
}

body .p-inputtext {
    border-radius: 2px;
}

body .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: $ln-color-black;
}

/* haven't figured out how to get this icon in place without screwing up the input box height
    the icon is a base64 encoded version of the Sainsbury's SVG
    do we even need this icon displayed? If not, delete this */

/* .p-column-filter {
    background: white url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiBjbGFzcz0ibG4tYy1pbnB1dC1ncm91cF9faWNvbiBsbi1jLWljb24iIHJvbGU9ImltZyIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOS42OTIgMTUuMzMzYTUuNjQxIDUuNjQxIDAgMSAwIDAtMTEuMjgyIDUuNjQxIDUuNjQxIDAgMCAwIDAgMTEuMjgyem02LjExNy0uOTc1bDUuODkgNS44OTFhMS4wMjYgMS4wMjYgMCAwIDEtMS40NSAxLjQ1bC01Ljg5LTUuODlhNy42OTIgNy42OTIgMCAxIDEgMS40NS0xLjQ1eiIgZmlsbD0iY3VycmVudENvbG9yIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=') !important;
    background-repeat: no-repeat !important;
    padding-left: 25px !important;
    color: #0c0c0c !important;
    height: 1.5rem !important;
} */

.p-datatable {
    background-color: $ln-color-white;
}

.p-datatable-wrapper {
    padding: 1rem;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
    background-color: $ln-color-grey-dark;
    color: $ln-color-white;
}

body .p-paginator {
    background-color: $ln-color-white;
    border: none;
    padding: 0;
}

body .p-paginator .p-paginator-pages .p-paginator-page {
    color: $ln-color-grey-dark;
}

/* MULTISELECT */

body .p-multiselect {
    border-radius: 2px;
}

body .p-checkbox-box {
    border-radius: 0;
}

body .p-checkbox .p-checkbox-box {
    border-radius: 0;
}

body .p-checkbox .p-checkbox-box.p-highlight {
    border-color: $ln-color-orange;
    background-color: $ln-color-orange;
}

body .p-multiselect .p-checkbox .p-checkbox-box.p-highlight {
    border-color: $ln-color-orange;
    background-color: $ln-color-orange;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: $ln-color-orange;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    color: $ln-color-orange;
}

body .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: $ln-color-orange-dark;
    background-color: $ln-color-orange-dark;
}

body .p-multiselect-close {
    display: none !important;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
    width: 80%;
}

/* FILE UPLOADER */

.p-fileupload .p-fileupload-buttonbar {
    border-radius: 2px;
}

.p-fileupload-content.p-fileupload-highlight {
    border-color: $ln-color-orange;
}

body .p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: $ln-color-orange;
}

body .p-button {
    background-color: $ln-color-white;
    border-radius: 2px;
    border: 2px solid $ln-color-orange;
    color: $ln-color-orange;
    font-size: 1.125rem;
    font-weight: 700;
    min-height: 48px;
    min-width: 49px;
}

body .p-button:enabled:hover {
    border-color: $ln-color-orange-dark;
    background-color: rgba(229, 80, 0, 0.08);
    color: $ln-color-orange-dark;
}

/* tooltip overrides */
body .p-tooltip-text {
    background-color: $ln-color-orange-dark !important;
    color: $ln-color-white !important;
    padding: 0.429em;
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.5);
    border-radius: 2px !important;
}
body .p-tooltip.p-tooltip-top {
    margin-top: -3px;
}
body .p-tooltip.p-tooltip-right {
    margin-left: 3px;
}
body .p-tooltip.p-tooltip-bottom {
    margin-top: 3px;
}
body .p-tooltip.p-tooltip-left {
    margin-left: -3px;
}
body .p-tooltip .p-tooltip-arrow {
    border-width: 6px;
    margin-top: -6px;
    margin-left: -6px;
}
body .p-tooltip.p-tooltip-right .p-tooltip-arrow,
body .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    top: 50%;
}
body .p-tooltip.p-tooltip-top .p-tooltip-arrow,
body .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    left: 50%;
}
body .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: $ln-color-orange-dark;
    bottom: -8px;
}
body .p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: $ln-color-orange-dark;
    left: -2px;
}
body .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: $ln-color-orange-dark;
    top: -2px;
}
body .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: $ln-color-orange-dark;
    right: -8px;
}
body .p-tooltip .p-tooltip-text {
    padding: 0.5rem 1rem;
    background-color: $ln-color-orange-dark !important;
    color: $ln-color-white !important;
    white-space: pre-line;
}

/* Panel */
body .p-panel-title {
    color: $ln-color-orange;
}

body .p-panel-titlebar {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
}

body .p-panel-content {
    border-bottom-left-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
}

/* Slider */
body .p-slider {
    background-color: $ln-color-grey-light;
    border: 0 none;
}

body .p-slider .p-slider-handle {
    height: 1.143em;
    width: 1.143em;
    background-color: $ln-color-white;
    border: 2px solid $ln-color-grey-dark;
    border-radius: 100%;
    transition: box-shadow 0.2s;
}

body .p-slider.p-slider-horizontal {
    height: 0.286em;
}

body .p-slider.p-slider-horizontal .p-slider-handle {
    top: 50%;
    margin-top: -0.5715em;
}

body .p-slider.p-slider-vertical {
    width: 0.286em;
}

body .p-slider.p-slider-vertical .p-slider-handle {
    left: 50%;
    margin-left: -0.5715em;
}

body .p-slider .p-slider-handle:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2em $ln-color-orange;
}

body .p-slider .p-slider-range {
    background-color: $ln-color-orange;
}

body .p-slider:not(.p-disabled) .p-slider-handle:hover {
    background-color: 2px solid $ln-color-grey-dark;
    border: 2px solid $ln-color-orange;
}

/* List box */
body .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    background-color: $ln-color-orange;
}

body .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    background-color: $ln-color-orange;
}

body .p-listbox .p-listbox-header .p-listbox-filter-container .p-listbox-filter-icon {
    color: $ln-color-orange;
}

/* Pick list */
body .p-picklist .p-picklist-caption {
    background-color: $ln-color-white;
    color: $ln-color-orange;
}
