@import '@jsluna/foundation/scss/main';

.selected {
    background-color: $ln-color-green;
    color: $ln-color-white;
}

.deselected {
    background-color: $ln-color-grey;
    color: $ln-color-red;
}

.selected,
.deselected {
    cursor: pointer;
}

.cutoff,
.select {
    margin-bottom: 0;
}

.cutoff span {
    color: $ln-color-orange;
}

.select {
    width: 6rem;
}
