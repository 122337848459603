@import '@jsluna/foundation/scss/main';

$ln-color-darker: #262626;
$ln-color-medium: #737373;
$ln-color-light: #d8d8d8;
$ln-color-lighter: #f2f2f2;

.planHeader {
    margin-top: 10px;
    border-color: $ln-color-orange !important;
    border-bottom-width: 2px !important;
}

button.ln-c-tabs__link:hover {
    border-color: $ln-color-orange-dark !important;
}
.planHeader > .ln-c-accordion__head {
    background-color: $ln-color-orange;
}

.planHeader.is-open > .ln-c-accordion__head {
    background-color: $ln-color-orange-dark;
}

.planHeader > .ln-c-accordion__head .ln-c-accordion__icon,
.planHeader > .ln-c-accordion__head h3 {
    color: white !important;
}

.planProcess .ln-c-list-group li:hover {
    cursor: default !important;
    pointer-events: none !important;
}

.planTile {
    min-height: 120px !important;
    border-width: medium !important;
    background-color: white;
}

.planTileHeading {
    color: $ln-color-orange !important;
    text-align: left;
    position: absolute;
    top: 3px;
    left: 10px;
}

.planTile .statusIcon {
    position: absolute;
    right: 2px;
    top: 2px;
}

.planTile .statusMessage {
    font-size: 0.8rem;
    position: absolute;
    top: 30px;
    text-align: center;
    width: 100%;
    left: 0;
}

.planTile .statusIcon svg {
    padding: 0.2rem;
    border-radius: 0.3rem;
    font-size: 1.3rem;
}
.planTile .activeIcon {
    color: $ln-color-orange !important;
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.planTile .planDate {
    position: absolute;
    left: 10px;
    bottom: 5px;
    font-size: 13px;
}

.fadeout {
    position: absolute;
    bottom: 5px;
    height: 25%;
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, white 70%);
    background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, white 70%);
    background-image: -ms-linear-gradient(rgba(255, 255, 255, 0) 0%, white 70%);
    width: calc(100% - 3.5rem);
}

.fadeout:hover,
ul:hover .fadeout {
    background: transparent;
}

.pipelineOptions {
    // font-size: 1.1rem;
    font-weight: bold;
}

.ln-c-list-group--full .ln-c-list-group__item {
    border-radius: 2px !important;
}

.ln-c-list-group--actionable li span {
    border-radius: 2px;
    margin: 3px 0;
}

.ln-c-list-group--actionable li .currentAction {
    text-align: left;
    position: absolute;
    display: block;
    top: 44%;
    transform: translateY(-44%);
    width: calc(100% - 115px);
    margin: 0;
}
.ln-c-list-group--actionable li .currentStatus {
    right: 8px;
    position: absolute;
    top: 0;
}

.status-complete .currentStatus svg {
    background-color: $ln-color-green;
    color: $ln-color-white;
}

.status-error .currentStatus svg,
.status-cancelled .currentStatus svg {
    background-color: $ln-color-red;
    color: $ln-color-white;
}

.ln-c-list-group--actionable li .currentStatus svg {
    border-radius: 2rem;
    padding: 0.5rem;
    font-size: 2rem;
    color: white;
}

.status-error:not(.is-active) .currentStatus svg {
    background-color: $ln-color-red;
    color: $ln-color-white;
}
.status-running:not(.is-active) .currentStatus svg {
    background-color: $ln-color-yellow;
    color: $ln-color-white;
}

.ln-c-list-group--actionable li .status-running .currentStatus svg {
    color: $ln-color-black;
}

.planProcess .ln-c-list-group__item,
.status-running {
    pointer-events: none !important;
}

.ln-c-list-group--actionable li span.is-active,
.ln-c-list-group--full .ln-c-list-group__item.status-undefined,
.ln-c-list-group--full .ln-c-list-group__item.status-pending {
    background-color: $ln-color-light !important;
}
.status-complete:not(.is-active) {
    background-color: #97e9a8 !important;
}

.status-skipped:not(.is-active) {
    background-color: #f5b041 !important;
}

.status-running {
    background-color: $ln-color-light;
    color: $ln-color-black;
    background-image: linear-gradient(to top, $ln-color-yellow, $ln-color-yellow);
    background-position: 0 0;
    background-repeat: no-repeat;
}
.status-cancelled:not(.is-active),
.status-error:not(.is-active) {
    background-color: #ff5c5c !important;
}
.status-running:not(.is-active) {
    background-color: #e9e897 !important;
}

.ln-c-tabs--pill .ln-c-tabs__link.is-active {
    background-color: $ln-color-orange;
    color: $ln-color-white !important;
}

.ln-c-tabs--pill .ln-c-tabs__link {
    // background-color: rgba(0, 0, 0, 0);
    // border-radius: 24px;
    // margin-right: 0.5rem;

    background-color: $ln-color-darker;
    border-radius: 2rem;
    margin-right: 0.5rem;
    border: 2px solid $ln-color-orange;
    font-size: 1.5rem;
    padding: 0 4rem;
}

.ln-c-tabs--pill .ln-c-tabs__list {
    text-align: center;
}

.tabHidden {
    display: none !important;
}

.p-column-title {
    height: 38px;
    display: block;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}
