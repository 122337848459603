// without this the NumericFilter box spills well over the edges of its container
.fixWidth :global .input-sm {
    width: 100%;
}

.fixWidth :global .react-grid-HeaderCell {
    text-align: center;
}

.gridTitle {
    position: relative;
    left: -220px;
    top: 3px;
    float: left;
}
