@import '@jsluna/foundation/scss/main';

.planHeader {
    margin-top: 10px;
    border-color: $ln-color-orange !important;
    border-bottom-width: 2px !important;
}

:global button.ln-c-tabs__link:hover {
    border-color: $ln-color-orange-dark !important;
}
.planHeader > :global.ln-c-accordion__head {
    background-color: $ln-color-orange;
}

.planHeader.is-open > :global.ln-c-accordion__head {
    background-color: $ln-color-orange-dark;
}

.planHeader > :global.ln-c-accordion__head .ln-c-accordion__icon,
.planHeader > :global.ln-c-accordion__head h3 {
    color: $ln-color-white !important;
}

.status-1 {
    background-color: $ln-color-orange;
    color: $ln-color-white;
    background-image: linear-gradient(to top, darkorange, darkorange);
    background-position: 0 0;
    background-repeat: no-repeat;
}

.status-2 {
    background-color: $ln-color-green;
    color: $ln-color-white;
}

.status-3 {
    background-color: $ln-color-red;
    color: $ln-color-white;
}

:global .ln-c-list-group--full :global .ln-c-list-group__item.status-undefined {
    background-color: $ln-color-grey-v-light !important;
}

:global .ln-c-list-group--actionable :global .ln-c-list-group__item.status-undefined:hover,
:global .ln-c-list-group--actionable :global .ln-c-list-group__item.status-undefined:focus {
    background-color: $ln-color-grey-light !important;
}

:global .ln-c-list-group--actionable :global .ln-c-list-group__item.status-1:hover,
:global .ln-c-list-group--actionable :global .ln-c-list-group__item.status-1:focus {
    background-color: $ln-color-orange !important;
    color: $ln-color-white !important;
}

:global .ln-c-list-group--actionable :global .ln-c-list-group__item.status-2:hover,
:global .ln-c-list-group--actionable :global .ln-c-list-group__item.status-2:focus {
    background-color: darkgreen !important;
    color: $ln-color-white !important;
}

:global .ln-c-list-group--actionable :global .ln-c-list-group__item.status-3:hover,
:global .ln-c-list-group--actionable :global .ln-c-list-group__item.status-3:focus {
    background-color: darkred !important;
    color: $ln-color-white !important;
}

:global .ln-c-list-group--full :global .ln-c-list-group__item {
    border-radius: 2px !important;
}

// :global .ln-c-list-group--actionable li span {
//     border-radius: 2px;
//     margin: 3px 0;
// }

// :global .ln-c-list-group--actionable li span span {
//     text-align: right;
//     position: absolute;
//     right: 10px;
//     display: block;
//     top: 44%;
//     transform: translateY(-44%);
// }

.planTile {
    min-height: 120px !important;
    border-width: medium !important;
    background-color: $ln-color-white;
}

.planTileHeading {
    color: $ln-color-orange !important;
}

.planTile :global .ln-c-icon {
    color: $ln-color-orange !important;
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.planTile .planDate {
    color: $ln-color-grey !important;
    position: absolute;
    left: 5px;
    bottom: 5px;
    font-size: 13px;
}

:global .ln-c-tabs--pill :global .ln-c-tabs__link.is-active {
    background-color: $ln-color-orange;
    color: $ln-color-white;
}

:global .ln-c-tabs--pill :global .ln-c-tabs__link {
    // background-color: rgba(0, 0, 0, 0);
    // border-radius: 24px;
    // margin-right: 0.5rem;

    background-color: rgba(0, 0, 0, 0);
    border-radius: 2rem;
    margin-right: 0.5rem;
    border: 2px solid $ln-color-orange;
    font-size: 1.5rem;
    padding: 0 4rem;
}

:global .ln-c-tabs--pill :global .ln-c-tabs__list {
    text-align: center;
}

.tabHidden {
    display: none !important;
}

:global .p-column-title {
    height: 38px;
    display: block;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}
