$ln-webpack: true;

@import "@jsluna/foundation/scss/settings";
@import "@jsluna/foundation/scss/tools";

@import "@jsluna/primer/scss/generic";
@import "@jsluna/primer/scss/elements";

@import "@jsluna/primer/scss/objects";
@import "@jsluna/grid/scss/objects";
@import "@jsluna/list/scss/objects";

@import "@jsluna/card/scss/components";
@import "@jsluna/date-picker/scss/components";
@import "@jsluna/footer/scss/components";
@import "@jsluna/foundation/scss/main";
@import "@jsluna/grid/scss/main";
@import "@jsluna/accordion/scss/main";
@import "@jsluna/header/scss/components";
@import "@jsluna/icons/scss/components";
@import "@jsluna/link/scss/components";
@import "@jsluna/list/scss/components";
@import "@jsluna/pagination/scss/components";
@import "@jsluna/progress/scss/components";
@import "@jsluna/site-layout/scss/main";
@import "@jsluna/table/scss/components";
@import "@jsluna/tabs/scss/components";

@import "@jsluna/utils/scss/utilities";
@import "@jsluna/button/scss/components";
@import "@jsluna/form/scss/components";
@import "@jsluna/autocomplete/scss/components";
@import "@jsluna/card/scss/components";
@import "@jsluna/footer/scss/components";
@import "@jsluna/header/scss/components";
@import "@jsluna/modal/scss/components";

@import "@jsluna/site-layout/scss/main";
@import "@jsluna/foundation/scss/main";
@import "@jsluna/icons/scss/components";
@import "@jsluna/link/scss/components";
@import "@jsluna/list/scss/components";
@import "@jsluna/table/scss/components";
@import "@jsluna/pagination/scss/components";

$colour: #f2f2f2 !important;
$ln-color-darker: #262626;
$ln-color-medium: #737373;
$ln-color-lighter: #f2f2f2;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.buttonActive.p-button,
.buttonActive {
    background-color: $ln-color-orange !important;
    color: $ln-color-white !important;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    cursor: default !important;
    pointer-events: none !important;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: $colour;
}

a {
    color: $ln-color-orange !important;
    transition-duration: 0.2s;
}

li a {
    color: #4c4c4c !important;
}

[tabindex]:focus,
a:focus,
button:focus {
    box-shadow: none !important;
    outline: none;
}

[tabindex]:active,
a:active,
button:active {
    border-style: none;
}

ul:hover,
li:hover {
    cursor: pointer;
}

.ln-c-modal__body a {
    text-decoration: none !important;
}

a h5 {
    color: $ln-color-orange !important;
}

a p {
    color: #4c4c4c !important;
}

.p-datatable {
    font-family: sans-serif;
}

.p-tooltip {
    pointer-events: none;
    z-index: 10;
}

.ln-c-tabs__link:hover {
    cursor: pointer;
}

.notificationCounter {
    background-color: $ln-color-red;
    position: absolute;
    height: 26px;
    width: 26px;
    top: -10px;
    border-radius: 20px;
    color: white;
    line-height: 20px;
    right: -10px;
    font-size: 13px;
    border: 2px solid white;
}

.iconOverlay {
    background-color: transparent;
    position: absolute;
    margin-top: -0.5em;
    margin-left: -0.5em;
    width: 1em;
    height: 1em;
    top: 50%;
    border-radius: 2px;
    color: $ln-color-red;
    line-height: normal;
    left: 50%;
    font-size: 1.25em;
    border: none;
    display: block;
}
.iconOverlay line {
    stroke: $ln-color-red;
    stroke-width: 3;
}

.cardAction {
    -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
    border: 2px solid white;
    transition-duration: 0.2s;
    transition-property: box-shadow, color, background-color, border-color;
    transition-timing-function: ease-out;
    position: relative;
    overflow: hidden;
}

.cardAction:hover,
.cardAction.active {
    transition-duration: 0.2s;
    transition-property: box-shadow, color, background-color, border-color;
    transition-timing-function: ease-out;
    cursor: pointer;
}

.cardAction.active {
    background-color: $ln-color-orange !important;
    color: white !important;
}

.cardAction:not(.active):hover {
    border-color: $ln-color-orange-dark !important;
}

.cardAction.active:hover {
    border-color: $ln-color-orange !important;
}

div.devLabel {
    display: none;
}

.ln-o-page__header {
    background-color: $ln-color-white;
}

.ln-c-header > .ln-o-container {
    background-color: transparent !important;
}

.DEV .ln-c-header,
.DEV .ln-c-header > .ln-o-container,
.LOCAL .ln-c-header,
.LOCAL .ln-c-header > .ln-o-container {
    background-image: none !important;
    background-color: transparent !important;
}

.DEV div.devLabel,
.LOCAL div.devLabel {
    display: block;
    position: fixed;
    bottom: 15px;
    right: -50px;
    transform: rotate(-45deg);
    padding: 6px 0;
    font-size: 1.5em;
    width: 180px;
    text-align: center;
    color: white;
    z-index: 1;
    pointer-events: none;
}

.DEV div.devLabel {
    background: rgba(120, 120, 120, 0.5) !important;
}

.LOCAL div.devLabel {
    background: rgba(70, 180, 255, 0.7) !important;
}

.loadingDots:after {
    content: "...";
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis-dot 2s infinite 0.7s;
    animation-fill-mode: fowards;
    width: 1.25em;
}

.alignRight .DayPicker {
    left: -115px;
}

.weekNumberLi {
    cursor: default;
}
.dateList .ln-c-list-group__item {
    margin-top: -1px !important;
    padding: 0;
}
.dateList .ln-c-list-group__item.weekNumberLi {
    margin-top: 0px !important;
    padding: 0;
}
.ln-o-grid .dateList .ln-c-list-group__item.weekNumberLi {
    margin-top: 0px !important;
    margin-left: -2px;
}
// .ln-o-grid .ln-c-form-group .dateList .ln-c-list-group__item.weekNumberLi {
//     margin-top: -1px !important;
//     margin-left: -1px;
// }
// .ln-c-modal .ln-c-form-group .dateList .ln-c-list-group__item.weekNumberLi {
//     margin-top: -0px !important;
//     margin-left: -1px;
// }
// .ln-c-accordion .dateList .ln-c-list-group__item.weekNumberLi {
//     margin-top: 0px !important;
//     margin-left: -2px;
// }

// .ln-c-modal .dateList .weekNumberLi {
//     margin-top: 1px !important;
//     margin-left: -1px;
// }
// .ln-c-accordion__body .weekNumberLi {
//     margin-top: 1px !important;
//     margin-left: -2px;
// }
.weekNumber {
    // position: absolute;
    // z-index: 1;
    height: 48px;
    border: 1px solid #e55000;
    background-color: #f06c00;
    color: white;
    border-radius: 0px 2px 2px 0px;
    // left: 131px;
    padding: 16px 11px 8px;
    // vertical-align: inherit;
    font-weight: bold;
    top: 1px; //32.2px;
    min-width: 48px;
    text-align: center;
    font-size: 20px;
    pointer-events: none;
}
.weekNumber::before {
    content: "Week";
    font-size: 12px;
    position: absolute;
    display: table;
    float: left;
    top: 2px;
    left: 0;
    text-align: center;
    white-space: nowrap;
    width: 100%;
}

@keyframes ellipsis-dot {
    25% {
        content: "";
    }
    50% {
        content: ".";
    }
    75% {
        content: "..";
    }
    100% {
        content: "...";
    }
}

.p-fileupload-content {
    overflow: auto;
    max-height: 300px;
}

::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    cursor: pointer;
}
::-webkit-scrollbar-track {
    background: #f2f2f2;
}
::-webkit-scrollbar-thumb {
    background: #d8d8d8;
}
:hover::-webkit-scrollbar-thumb {
    background: #d8d8d8;
}
::-webkit-scrollbar-thumb:horizontal:hover,
::-webkit-scrollbar-thumb:vertical:hover {
    background: #737373;
}
::-webkit-scrollbar-thumb:horizontal:active,
::-webkit-scrollbar-thumb:vertical:active {
    background: #737373;
}
